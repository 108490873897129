.homewr-section {
    background: #010103;
}

.homewr-section .text-box .p {
    color: #FFF;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
    padding-right: 3rem;
}

.homewr-section .text-box .p span {
    color: #1EEDA2;
    font-family: Lora;
    font-size: 28px;
    font-style: italic;
    font-weight: 600;
    line-height: 150%;
}

.homewr-section .ms-slider {
    display: inline-block;
    height: 1.5em;
    overflow: hidden;
    vertical-align: middle;
    mask-image: linear-gradient(transparent, white, white, white, transparent);
    mask-type: luminance;
    mask-mode: alpha;
  }
  .ms-slider__words {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    text-transform:uppercase;
    animation-name: wordSlider;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    animation-duration: 7s;
  }
  .ms-slider__word {
    display: block;
    line-height: 1.3em;
    text-align: left;
    color: #FFF;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 48px */
    text-transform: uppercase;
  }
  
  @keyframes wordSlider {
    0%, 27% {
      transform: translateY(0%);
    }
    33%, 60% {
      transform: translateY(-25%);
    }
    66%, 93% {
      transform: translateY(-50%);
    }
    100% {
      transform: translateY(-75%);
    }
  }

.homewr-section .text-box::before {
    height: 85%;
    top:7%;
}

.Aifirst-section {
    padding-top: 4rem!important;
    padding-bottom: 2rem!important    
}

.Aifirst-section .row {
    justify-content: center;
    align-items: center;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}

.Aifirst-section .row:last-child {
    padding-top: 0;
    padding-bottom: 0;
}

.text-right {
    text-align: right !important;
}

.Aifirst-section .text-box h2 {
    color: #1A202C;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; 
}

.Aifirst-section .text-box h2 span { 
    color: #1EC337;
    font-family: Lora;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
}

.bl-green {
    position: relative;
    padding-top: 2em;
    padding-bottom: 2em;
}

.text-box {
    position: relative;
}

.bl-green:before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    background: #1EEDA2;
    top: 0%;
    left: -30px;
}


.bl-green .text-box::before {
    content: "";
    width: 11px;
    height: 100%;
    position: absolute;
    background: #1EEDA2;
    top: 0%;
    left: -30px;
}

.Aifirst-section h3 {
    color: #000;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.Aifirst-section h4 {
    color: #1EC337;
    font-family: Lora;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}

.Aifirst-section p {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.Aifirst-section .slide-btn {
    display: inline-flex;
    padding: 19.323px 30.511px;
    justify-content: center;
    align-items: center;
    gap: 10.17px;
    border-radius: 5px;
    background: #1EEDA2;
    text-decoration: none;
    color: #000;
    font-family: Montserrat;
    font-size: 16.272px;
    font-style: normal;
    font-weight: 500;
    line-height: 87.5%;
}



@media screen and (max-width: 767px) {
    .homewr-section .text-box .p {
        padding-right: 0px;
    }

    .homewr-section .bl-green {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .homewr-section .bl-green:before {
        top: 0%;
        left: 18px;
        height: 85%;
    }


    .Aifirst-section .bl-green:before {
        top: 10%;
        height: 80%;
    }

    .homewr-section .text-box .p,
    .homewr-section .text-box .p span {
        font-size: 14px;
    }
 
    .homewr-section .ms-slider {
        display:block;
        height: 3rem;
    }

    .Aifirst-section {
        padding-top: 2rem !important;
    }
    
.Aifirst-section .text-box h2 {
    font-size: 22px; 
}

.Aifirst-section .text-box h2 span { 
    font-size: 24px;
}

.Aifirst-section .container:first-child {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.Aifirst-section .container:last-child {
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 3rem;
}

.Aifirst-section .container:last-child .col-md-6 { 
    margin-bottom: 1.5rem;
}
.Aifirst-section .container:last-child .text-right {
    text-align: center !important;
}

.Aifirst-section img {
    width: 50%;
    text-align: center;
}

.Aifirst-section h3 {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;    
}

.Aifirst-section h4 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
}

.Aifirst-section p {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
}

.Aifirst-section a.slide-btn {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;

}
    
}

@media (min-width: 768px) and (max-width: 1024px) {
    
    .homewr-section .text-box .p {
        padding-right: 0px;
    }

    .homewr-section .bl-green {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .homewr-section .bl-green:before {
        top: 0%;
        left: 18px;
        height: 85%;
    }


    .Aifirst-section .bl-green:before {
        top: 10%;
        height: 80%;
    }

    .homewr-section .text-box .p,
    .homewr-section .text-box .p span {
        font-size: 14px;
    }
 
    .homewr-section .ms-slider {
        display:block;
        height: 3rem;
    }

    .Aifirst-section {
        padding-top: 2rem !important;
    }
    
.Aifirst-section .text-box h2 {
    font-size: 22px; 
    text-align: left;
}

.Aifirst-section .text-box h2 span { 
    font-size: 24px;
    text-align: left;
}

.Aifirst-section .container:first-child {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.Aifirst-section .container {
    padding-left: 5%;
    padding-right: 5%;
}

.Aifirst-section .container:last-child {
    text-align: left;
    padding-left: 2%;
    padding-right: 2%;
    margin-bottom: 3rem;
}

.Aifirst-section .container:last-child .col-md-6 { 
    margin-bottom: 1.5rem;
}
/* .Aifirst-section .container:last-child .text-right {
    text-align: center !important;
} */

.Aifirst-section img {
    width: 70%;
    text-align: center;
}

.Aifirst-section h3 {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;    
}

.Aifirst-section h4 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.Aifirst-section p {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.Aifirst-section a.slide-btn {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
}


}