.disruptors-section {
   padding-top: 100px;
   padding-bottom: 100px;
}

.disruptors-section img {
    width: 100%;
    width: auto;
    max-height: 100px;
}


.disruptors-section h1 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 60px */
}

.disruptors-section h1 span {
    color: #1EC337;
    font-family: Lora;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}

.disruptors-section .brand-slider .swiper-wrapper {
    align-items: center;
    text-align: center;
}

.disruptors-section .brand-slider img.small {
    max-height: 30px;
}

.disruptors-section .brand-slider img.small60 {
    max-height: 60px;
}


/* 
.disruptors-section .brand-slider .swiper-pagination .swiper-pagination-bullet {
    background-color: #000;
} */

/* .disruptors-section .slider-active .swiper-wrapper {
    justify-content: normal; 
  } */

  @media screen and (max-width: 767px) { 
    
    .disruptors-section {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .disruptors-section h1 {
        font-size: 22px;
        text-align: center;
    }

    .disruptors-section h1 span {
        font-size: 24px;
        text-align: center;
    }
 
    .disruptors-section .brands-logo {
        display: grid;
        grid-template-columns: 50% 50%;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        gap: 30px 10px;
        width: 80%;
        margin: auto;
    }

    .disruptors-section .brands-logo img {
        width  : auto;
        height: auto;    
        margin-right: auto;
    }

    .disruptors-section .brands-logo div:last-child img{
        height: 35px;
    }

    /* .disruptors-section .brands-logo img.small {
        max-height: 75px;
        width: auto;
        margin: auto;
    }

    .disruptors-section .brands-logo img.small10,
    .disruptors-section .brands-logo img.small12,
    .disruptors-section .brands-logo img.small13,
    .disruptors-section .brands-logo img.small14,
    .disruptors-section .brands-logo img.small15,
    .disruptors-section .brands-logo img.small16 {
        width: auto;
    }

    .disruptors-section .brands-logo img.small10 {
        max-height: 30px;
    }

    .disruptors-section .brands-logo img.small12 {
        max-height: 40px;
    }

    
    .disruptors-section .brands-logo img.small13 {
        max-height: 75px;
    }

    .disruptors-section .brands-logo img.small15,
    .disruptors-section .brands-logo img.small16 {
        max-height: 60px;
    } */

  }



  @media (min-width: 768px) and (max-width: 1024px) { 
    .disruptors-section {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .disruptors-section h1 {
        font-size: 22px;
        text-align: center;
    }

    .disruptors-section h1 span {
        font-size: 24px;
        text-align: center;
    }
 
    .disruptors-section .brands-logo {
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        gap: 30px 10px;
        width: 75%;
        margin: auto;
    }

    .disruptors-section .brands-logo img {
        width  : auto;
        height: auto;    
        margin-right: auto;
        margin-left: auto;
    }

    .disruptors-section .brands-logo div:last-child img{
        height: 35px;
    }
    
  }



  