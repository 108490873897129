.nextchapter-section {
    padding: 100px 0px;
    background: #000000;
}

.bl-green {
    position: relative;
    padding-top: 2em;
    padding-bottom: 2em;
}

.text-box {
    position: relative;
}

.bl-green:before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    background: #1EEDA2;
    top: 0%;
    left: -30px;
}


.bl-green .text-box::before {
    content: "";
    width: 11px;
    height: 100%;
    position: absolute;
    background: #1EEDA2;
    top: 0%;
    left: -30px;
}

.nextchapter-section .bl-green h2.text-box  {
    font-family: Montserrat;
    font-size: 40px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;    
    color: #fff;
}

.nextchapter-section .bl-green h2.text-box span { 
    color: #1EC337;
    font-family: Lora;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
}

.nextchapter-section .left-side {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
}

.nextchapter-section .left-side .tabs .tablinks {
    color: #ffffff;
    padding: 14px 24px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    cursor: pointer;
}

.nextchapter-section .left-side .tabs .tablinks.active {
    background: #ffffff;
    color: #090808;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    box-shadow: -6px 0px 0px 0px #0281DF;

}

.nextchapter-section .left-side p {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;   
    color: #A2A2A2;
}

.nextchapter-section .left-side p a {
    color: #1EC337;
}

.nextchapter-section .right-side {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: 800px;
    overflow-y: auto;
    gap:50px;
}

.nextchapter-section .right-side .position h3 {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: #090808;
}

.nextchapter-section .right-side .position h3 {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: #090808;
}


.nextchapter-section .right-side .position ul.tag {
    display: flex;
    padding-left: 0px;
    list-style: none;
    gap: 20px;
}

.nextchapter-section .right-side .position ul.tag li {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    border: 1px solid #090808;
    border-radius: 30px;
    padding: 8px 16px;
}

.nextchapter-section .right-side .position ul.tag li span {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
}

.nextchapter-section .right-side .position p {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
} 

.nextchapter-section .right-side  .tabcontent {
    display: none;
    transition:all .5s;
}

.nextchapter-section .right-side  .tabcontent.active {
    display: block;
 }
 
.nextchapter-section .right-side .position {
    background: #ffffff;
    padding: 21px 34px;
}

.nextchapter-section .right-side .position .view-more {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: right;
    color:#16D691;
    margin-right: auto;
    padding: 20px 30px;
    cursor: pointer;
}


.nextchapter-section .right-side .position .more-content {
    display:none;
    transition: all 0.5s ease;
}
.nextchapter-section .right-side .position .more-content.show {
    display: block;
}

.nextchapter-section .right-side .position .more-content h5,
.nextchapter-section .right-side .position .more-content h3,
.nextchapter-section .right-side .position .more-content strong {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}

.nextchapter-section .right-side .position .more-content ul li {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;    
}


.nextchapter-section .right-side .position .tablinks {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color:#090808;    
}



@media screen and (max-width: 767px) {

.nextchapter-section .bl-green h2.text-box span {
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
}

.nextchapter-section .bl-green h2.text-box {
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
}

.nextchapter-section .left-side .tabs {
    display: flex;
    /* flex-wrap: wrap; */
    width: 100%;
    overflow-x: scroll;
    border-bottom: 1px solid #ffffff;
}

.nextchapter-section .left-side .tabs .tablinks {
    
    color: #A0A0A0;
    padding: 7px 14px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    white-space: nowrap;
    border-bottom: 4px solid transparent;
}


.nextchapter-section .left-side .tabs .tablinks.active {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    background: unset;
    border-color: #0281DF;
    box-shadow: unset;
}

.nextchapter-section .right-side {
    max-height: unset;
    margin: 25px 15px 0px; 
    gap: 25px;
}

.nextchapter-section .right-side .position h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}


.nextchapter-section .right-side .position ul.tag {
    flex-wrap: wrap;
    gap: 10px;
}


.nextchapter-section .right-side .position ul.tag li ,
.nextchapter-section .right-side .position ul.tag li span {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
}

.nextchapter-section .right-side .position p {
    font-size: 12px;
    line-height: 19px;    
} 

.nextchapter-section .right-side .position .more-content h5,
.nextchapter-section .right-side .position .more-content h3,
.nextchapter-section .right-side .position .more-content strong {
    font-size: 12px;
    line-height: 19px;    
    font-weight: 600;
}

.nextchapter-section .right-side .position .more-content,
.nextchapter-section .right-side .position .more-content ul li {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    
}

.nextchapter-section .right-side .position .view-more {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
}

}



@media (min-width: 768px) and (max-width: 1024px) {

    .nextchapter-section {
        padding:50px 0px;
    }

    .nextchapter-section .bl-green h2.text-box span {
        font-size: 22px;
        font-style: italic;
        font-weight: 400;
        line-height: 33px;
        letter-spacing: 0em;
        text-align: left;
    }
    
    .nextchapter-section .bl-green h2.text-box {
        font-size: 22px;
        font-weight: 400;
        line-height: 33px;
        letter-spacing: 0em;
        text-align: left;
    }

    /* .nextchapter-section .left-side .tabs .tablinks {
        
        color: #A0A0A0;
        padding: 7px 14px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        white-space: nowrap;
        border-bottom: 4px solid transparent;
    }
    
    
    .nextchapter-section .left-side .tabs .tablinks.active {
        color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        text-align: left;
        background: unset;
        border-color: #0281DF;
        box-shadow: unset;
    }
 */

    /* .nextchapter-section .bl-green h2.text-box  {
        font-family: Montserrat;
        font-size: 40px;
        font-weight: 400;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: left;    
        color: #fff;
    }
    
    .nextchapter-section .bl-green h2.text-box span { 
        color: #1EC337;
        font-family: Lora;
        font-size: 40px;
        font-style: italic;
        font-weight: 400;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: left;
    } */
    
    .nextchapter-section .left-side {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 30px;
    }
    
    .nextchapter-section .left-side .tabs .tablinks {
        color: #ffffff;
        padding: 14px 12px;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        cursor: pointer;
    }
    
    .nextchapter-section .left-side .tabs .tablinks.active {
        background: #ffffff;
        color: #090808;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        box-shadow: -6px 0px 0px 0px #0281DF;
    
    }
    
    .nextchapter-section .left-side p {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;   
        color: #A2A2A2;
    }
    
    .nextchapter-section .left-side p a {
        color: #1EC337;
    }
    



    
    .nextchapter-section .right-side {
        margin: 0px 15px 0px; 
        gap: 25px;
    }
    
    .nextchapter-section .right-side .position h3 {
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
    }
    
    
    .nextchapter-section .right-side .position ul.tag {
        flex-wrap: wrap;
        gap: 10px;
    }
    
    
    .nextchapter-section .right-side .position ul.tag li ,
    .nextchapter-section .right-side .position ul.tag li span {
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
    }
    
    .nextchapter-section .right-side .position p {
        font-size: 12px;
        line-height: 19px;    
    } 
    
    .nextchapter-section .right-side .position .more-content h5,
    .nextchapter-section .right-side .position .more-content h3,
    .nextchapter-section .right-side .position .more-content strong {
        font-size: 12px;
        line-height: 19px;    
        font-weight: 600;
    }
    
    .nextchapter-section .right-side .position .more-content,
    .nextchapter-section .right-side .position .more-content ul li {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        
    }
    
    .nextchapter-section .right-side .position .view-more {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0em;
    }
    
    }
    