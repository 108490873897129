.insight-section .relative {
    position: relative;
}

.insight-section .insights-list { 
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    gap: 50px;
}

.insight-section .insight {
    max-width: 400px;
}

.insight-section .insight a {
    text-decoration: none;
}

.insight-section img {
    width: 100%;
}

.insight-section .over {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 0px;
    padding: 10px;
    width: 100%;
    color: #ffffff;
}

.insight-section .over .readtime {
    font-family: Montserrat;
    font-size: 9.45px;
    font-weight: 400;
    line-height: 17.38px;
    text-align: left;
    display: flex;
    gap: 5px;
    background: #ffffff40;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
}

.insight-section .over .share {
    padding: 5px 15px;
    border-radius: 50px;
    background: #ffffff20;
}

.insight-section .author-line {
    display: flex;
    justify-content: space-between;
}

.insight-section .author-line .author  {
    display: flex;
    gap:5px;
    align-items: center;
}

.insight-section .author-line .author .author-icon {
    max-width: 25px;
    max-height: 25px;
}

.insight-section .author-line .author .author-icon img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
}

.insight-section .author-line .author .author-des div:first-child {
    font-family: Montserrat;
    font-size: 14.18px;
    font-weight: 400;
    line-height: 21.48px;
    text-align: left;    
}

.insight-section .author-line .author .author-des div:last-child {
    font-family: Montserrat;
    font-size: 11.03px;
    font-weight: 400;
    line-height: 17.64px;
    text-align: left;
    color: #909090;
}

.insight-section .author-line .share {
    font-family: Montserrat;
    font-size: 9.67px;
    font-weight: 400;
    line-height: 21.48px;
    text-align: left;
    color: #6C757D;
}

.insight-section h2 {
    color: #121416;
    font-family: Montserrat;
    font-size: 18.9px;
    font-weight: 600;
    line-height: 26.47px;
    text-align: left;
}

.insight-section p {
    color: #6C757D;
    font-family: Montserrat;
    font-size: 10.74px;
    font-weight: 400;
    line-height: 16.08px;
    text-align: left;    
}


.insight-section .link {
    font-family: Montserrat;
    font-size: 12.89px;
    font-weight: 400;
    line-height: 21.48px;
    text-align: left;
    color: #121416;    
    text-decoration: none;
    position: relative;
}

.insight-section .link:after {
    content: "";
    position: absolute;
    height: 1px;
    width: 94%;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    background: #121416;
}

.insight-section button {
    color: #03AD9B;
    text-align: center;
    font-family: Helvetica;
    font-size: 18px;
    line-height: 21px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    /* width: 313px;
    height: 83px;
    padding: 15px 17px; */
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid #03A796;
    background-color: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
}
.insight-section p.anima span.anima-underline {
    color: transparent;
    display: inline-block;
    overflow: hidden;
    position: relative;
    width: 179px;
    text-decoration: none;
    vertical-align: bottom;
}

.insight-section p.anima span.anima-underline:after {
    background: linear-gradient(to bottom, rgba(20, 20, 20, 0.8), rgba(20, 20, 20, 0.8)) center 1.08em/100% 5px no-repeat;
    content: "";
    height: 100%;
    margin-top: 0px;
    right: 0;
    position: absolute;
    top: 0;
    width: 400%;
    will-change: transform;
    z-index: 1;
    -webkit-animation: underline-gradient 6s linear infinite;
    animation: underline-gradient 6s linear infinite;
    background-image: linear-gradient(270deg, rgba(241, 21, 118, 1) 0%, rgba(1, 198, 178, 1) 20%, rgba(241, 21, 118, 1) 40%, rgba(1, 198, 178, 1) 60%, rgba(241, 21, 118, 1) 80%, rgba(1, 198, 178, 1) 100%);

}
/* .insight-section .row:nth-child(4){
    margin-top: 30px !important;
    padding-top: 30px !important;
} */


ul.paginationBttns {
    list-style: none;
    display: flex;
    justify-content: right;
}

ul.paginationBttns li {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #ddd;
}


ul.paginationBttns li.paginationActive {
    background: linear-gradient(180deg, #01C6B2 0%, #048D7F 128.35%);
    background: #1EEDA2;
    background: #0281DF;
    color: white;
    border: 1px solid #01C6B2;
    border-color: #0281DF;
}

ul.paginationBttns li:hover:not(.paginationActive) {
    color: #ffffff;
    background: linear-gradient(180deg, #01C6B2 0%, #048D7F 128.35%);
    background: #0281DF;
   
}


@media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    
    .insight-section button {
        height: 48px;
        width: 180px;
        margin: auto;    
        font-size: 14.8862px;
        line-height: 17px;
    }

    .insight-section .insights-list { 
        display: grid;
        grid-template-columns: auto;
        justify-content: center;
        gap: 50px;
        width: 95%;
        margin: auto;
    }
}


@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
    
    .insight-section button {
        height: 48px;
        width: 180px;
        margin: auto;    
        font-size: 14.8862px;
        line-height: 17px;
        
    }

    .insight-section .insights-list { 
        display: grid;
        grid-template-columns: 45% 45%;
        justify-content: space-between;
        gap: 50px;
        width: 90%;
        margin: auto;
    }

    
    .insight-section .insights-list .insight {
        max-width: 350px;
    }
}

