.investmentethos-section {
    padding: 100px 0px;
}


.investmentethos-section .bl-green h2 {
    color: #1A202C;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; 
}

.investmentethos-section .bl-green h2 span { 
    color: #1EC337;
    font-family: Lora;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}

.bl-green {
    position: relative;
    padding-top: 2em;
    padding-bottom: 2em;
}

.text-box {
    position: relative;
}

.bl-green:before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    background: #1EEDA2;
    top: 0%;
    left: -30px;
}


.bl-green .text-box::before {
    content: "";
    width: 11px;
    height: 100%;
    position: absolute;
    background: #1EEDA2;
    top: 0%;
    left: -30px;
}

.investmentethos-section  .bl-green .text-box p {
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 300;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
}

.icon-box {
    padding: 40px 20px 30px 40px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
}

.icon-box .icon svg     { 
    margin-bottom: 20px;
}

.icon-box h4 {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #101010;   
}

.icon-box p {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

@media screen and (max-width: 767px) {
    .investmentethos-section {
        padding: 0px 0px 50px 0px;
    }

.investmentethos-section  .col-md-6.ps-5 {
    width: 100%;
    padding-left: 1rem !important;
    margin-bottom: 20px;
}

.investmentethos-section .col-md-3 {
    width: 50% !important;
    margin-bottom: 20px;
}

.investmentethos-section .row.justify-content-between {
    --bs-gutter-y: 20px;
}

.investmentethos-section .bl-green h2,
.investmentethos-section .bl-green h2 span  {
    font-size: 22px;
    line-height: 150%;
 }

 
 .investmentethos-section  .bl-green {
    padding-right: 1rem !important;
 }
 
 .investmentethos-section  .bl-green .text-box {
    padding-right: 0px !important;
 }

 .investmentethos-section  .bl-green .text-box p {
    font-size: 16px;   
    line-height: 24px;
 }

 
 .investmentethos-section .icon-box {
    padding: 15px 10px 0px 20px;
 }

 .investmentethos-section  .icon-box .icon svg {
    margin-bottom: 5px;
    width: 30px;
 }
 
 .icon-box h4 {
    font-size: 14px;
    line-height: 17.7px;
}

.icon-box p {
    font-size: 12px;
    line-height: 18px;
}

}


@media (min-width: 768px) and (max-width: 1024px) {
    .investmentethos-section {
        padding: 0px 0px 50px 0px;
    }

.investmentethos-section  .col-md-6.ps-5 {
    width: 100%;
    padding-left: 1rem !important;
    margin-bottom: 20px;
}

.investmentethos-section .col-md-3 {
    width: 50% !important;
    margin-bottom: 20px;
}

.investmentethos-section .row.justify-content-between {
    --bs-gutter-y: 20px;
}

.investmentethos-section .bl-green h2,
.investmentethos-section .bl-green h2 span  {
    font-size: 22px;
    line-height: 150%;
 }

 
 .investmentethos-section  .bl-green {
    padding-right: 1rem !important;
 }
 
 .investmentethos-section  .bl-green .text-box {
    padding-right: 0px !important;
 }

 .investmentethos-section  .bl-green .text-box p {
    font-size: 16px;   
    line-height: 24px;
 }

 
 .investmentethos-section .icon-box {
    padding: 15px 10px 0px 20px;
 }

 .investmentethos-section  .icon-box .icon svg {
    margin-bottom: 5px;
    width: 30px;
 }
 
 .icon-box h4 {
    font-size: 14px;
    line-height: 17.7px;
}

.icon-box p {
    font-size: 12px;
    line-height: 18px;
}

}