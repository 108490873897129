.tomorrowschallenges-section {
    padding: 100px 0px;
}

.tomorrowschallenges-section .main-box {
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.tomorrowschallenges-section .main-box h1 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 60px */
}

.tomorrowschallenges-section .main-box h1 span {
    color: #1EC337;
    font-family: Lora;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}


/* tomorrowschallenges-section */
.tomorrowschallenges-section {
    padding-top: 0rem!important;
    padding-bottom: 2rem!important    
}

.tomorrowschallenges-section .row {
    justify-content: center;
    align-items: center;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}

.tomorrowschallenges-section img {
    width: 100%;
}

.text-right {
    text-align: right !important;
}

.tomorrowschallenges-section .text-box h2 {
    color: #1A202C;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; 
}

.tomorrowschallenges-section .text-box h2 span { 
    color: #1EC337;
    font-family: Lora;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}

.bl-green {
    position: relative;
    padding-top: 2em;
    padding-bottom: 2em;
}

.text-box {
    position: relative;
}

.bl-green:before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    background: #1EEDA2;
    top: 0%;
    left: -30px;
}


.bl-green .text-box::before {
    content: "";
    width: 11px;
    height: 100%;
    position: absolute;
    background: #1EEDA2;
    top: 0%;
    left: -30px;
}

.tomorrowschallenges-section h3 {
    color: #000;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.tomorrowschallenges-section p {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
}

.tomorrowschallenges-section .bg-1 {
    background: url('../images/Product-Ideation.webp');
    background-repeat: no-repeat;
    /* background-position: top center; */
    background-size: contain;
    background-attachment: fixed;
    height:100vh;
    width: 100%;
}

.tomorrowschallenges-section .bg-2 {
    background: url('../images/Product-Engineering.webp');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto 100vh;
    /* background-size: auto; */
    background-attachment: fixed;
    /* padding: 499px 272px; */
    height:100vh;
    
    width: 100%;
}


.tomorrowschallenges-section .bg-3 {
    background: url('../images/AI-Co-Pilot.webp');
    background-repeat: no-repeat;
    /* background-position: top center; */
    background-size: contain;
    background-attachment: fixed;
    height:100vh;
    width: 100%;

}

.tomorrowschallenges-section .bg-4 {
    background: url('../images/Innovation-Labs.webp');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto 100vh;
    /* background-size: auto; */
    background-attachment: fixed;
    /* padding: 499px 272px; */
    height:100vh;    
    width: 100%;
}

.tomorrowschallenges-section .bg-5 {
    background: url('../images/MLOpsLLMops.webp');
    background-repeat: no-repeat;
    /* background-position: top center; */
    background-size: contain;
    background-attachment: fixed;
    height:100vh;
    width: 100%;
} 

.tomorrowschallenges-section .text-side h3 {
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.tomorrowschallenges-section .text-side h4 {
    font-family: Lora;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #1EC337;
}


.tomorrowschallenges-section .text-side p {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.tomorrowschallenges-section .text-side ul  {
    padding-left: 20px;
    list-style-image: url('../images/Ornament44.svg');    
}

.tomorrowschallenges-section .text-side ul li {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000  ;
}


.tomorrowschallenges-section .text-side ul li::before {
    content: ""; 
    width: 11.52px;
    height: 11.52px;
    top: 353.14px;
    left: 95.14px;
    color:#010103;
  }

  .tomorrowschallenges-section .text-side h5 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .tomorrowschallenges-section .text-side p.inline-list {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;    
  }


  @media screen and (max-width: 767px) {
    
    .tomorrowschallenges-section {
        padding-top: 0px !important;
    }

    .tomorrowschallenges-section .text-side ul  {
        list-style-image: url('../images/Ornament-mobile.png');    
    }

    .tomorrowschallenges-section .text-side ul  {
        padding-left: 0px;
        list-style-image: url('../images/Ornament-mobile.png');   
        list-style: none; 
    }

    .tomorrowschallenges-section .text-side ul li {
        background: url('../images/Ornament44.svg') no-repeat;
        background-position:0px 3px ;
        padding-left:20px;
    }


    .tomorrowschallenges-section .main-box h1 {
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 300;
        line-height: 33px;
        letter-spacing: 0em;
        text-align: center;    
    }

    .tomorrowschallenges-section .main-box h1 span{
        font-family: Lora;
        font-size: 24px;
        font-style: italic;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;

    }

    .competitiveedge-section .main-box h1 {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;        
    }

    .competitiveedge-section .main-box h1 span {
        font-family: Lora;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;        
    }

    .tomorrowschallenges-section .text-side p {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }

    .tomorrowschallenges-section .text-side ul li {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }

    .tomorrowschallenges-section .text-side h5 {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }

    .tomorrowschallenges-section .text-side p.inline-list {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }

    .tomorrowschallenges-section .bg-img {
        /* background: url('../images/Cloud-Modernization.webp'); */
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        background-attachment: unset;
        height:auto;    
        width: 100%;
        position: relative;
        margin-bottom: 150px;
    }

    .tomorrowschallenges-section .bg-img.bg-5 {
        margin-bottom: 100px;
    }

    .tomorrowschallenges-section .text-side {
        /* position: absolute; */
        background: #ffffff;
        width: 85%;
        margin: auto;
        transform: translateY(100px);
        padding: 5%;
    }

    .tomorrowschallenges-section  .main-btn {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: -15px;
    }
}


@media (min-width: 768px) and (max-width: 1024px) {
    
    .tomorrowschallenges-section {
        padding-top: 0px !important;
    }

    .tomorrowschallenges-section .text-side ul  {
        list-style-image: url('../images/Ornament-mobile.png');    
    }

    .tomorrowschallenges-section .text-side ul  {
        padding-left: 0px;
        list-style-image: url('../images/Ornament-mobile.png');   
        list-style: none; 
    }

    .tomorrowschallenges-section .text-side ul li {
        background: url('../images/Ornament44.svg') no-repeat;
        background-position:0px 3px ;
        padding-left:20px;
    }


    .tomorrowschallenges-section .main-box h1 {
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 300;
        line-height: 33px;
        letter-spacing: 0em;
        text-align: center;    
    }

    .tomorrowschallenges-section .main-box h1 span{
        font-family: Lora;
        font-size: 24px;
        font-style: italic;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;

    }

    .competitiveedge-section .main-box h1 {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;        
    }

    .competitiveedge-section .main-box h1 span {
        font-family: Lora;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;        
    }

    .tomorrowschallenges-section .text-side p {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }

    .tomorrowschallenges-section .text-side ul li {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }

    .tomorrowschallenges-section .text-side h5 {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }

    .tomorrowschallenges-section .text-side p.inline-list {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }

    .tomorrowschallenges-section .bg-img {
        /* background: url('../images/Cloud-Modernization.webp'); */
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        background-attachment: unset;
        height:auto;    
        width: 100%;
        position: relative;
        margin-bottom: 150px;
    }

    .tomorrowschallenges-section .bg-img.bg-4 {
        margin-bottom: 100px;
    }

    .tomorrowschallenges-section .text-side {
        /* position: absolute; */
        background: #ffffff;
        width: 85%;
        margin: auto;
        transform: translateY(100px);
        padding: 5%;
    }

    .tomorrowschallenges-section  .main-btn {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: -15px;
    }
}
