.menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    /* background: linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.42) 28.65%, rgba(0, 0, 0, 0.35) 65.63%, rgba(0, 0, 0, 0.00) 100%); */
}

.menu.fixed {
    position: fixed;
    background: #000;
    transition: all 0.5s ease;
}


.menu .navbar-nav a {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    border-bottom: none;
    color: #FFFFFF;
}

.navbar-brand img {
    max-width: 189px;
}

.navbar-expand-md .navbar-nav .nav-link {
    margin: 0px 12px;
    line-height: 0px;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.7em;
    vertical-align: .1em;
    content: "";
    border-top: .4em solid;
    border-right: .35em solid transparent;
    border-bottom: 0;
    border-left: .35em solid transparent;
}


/* .services.nav-item.dropdown:has(a.active):before {
    position: absolute;
    content: "";
    left: 0px;
    bottom: 0px;
    height: 3px;
    width: 100%;
    background: linear-gradient(270deg, rgba(241,21,118,1) 0%, rgba(1,198,178,1) 20%, rgba(241,21,118,1) 100%);    
} */

/* .dropdown-toggle::before:has(> a.active){
    position: absolute;
    content: "";
    left: 0px;
    bottom: 0px;
    height: 3px;
    width: 100%;
    background: linear-gradient(270deg, rgba(241,21,118,1) 0%, rgba(1,198,178,1) 20%, rgba(241,21,118,1) 100%);
} */

/*  */
.menu .navbar-nav .dropdown-menu {
    top:30px
}

.menu .navbar-nav .desktop.dropdown-menu.show { 
    background: #03172E;
}


.menu .navbar-nav .dropdown-menu.show {
    /* border-radius: 0;
    background: rgba(255, 255, 255, 0.3);
    background: #202E4B;
    opacity: 0.8;
    border: 1px solid #03A796;
    padding: 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
 */
    border-radius: 2px;
    padding: 0px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.20);
    border: none;
    padding-left: 20px;
    background: #03172E;
}

.menu .desktop.dropdown:hover .dropdown-menu {
    display: block;
    /* border-radius: 0;
    background: rgba(255, 255, 255, 0.3);
    background: #202E4B;
    opacity: 0.8;
    border: 1px solid #03A796;
    padding: 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 2px;
    background: #03172E;
    padding: 0px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.20);
    border: none;
    /* padding-left: 20px; */
}

.menu .desktop.dropdown-menu:hover {
    display: flex;
} 



.menu .navbar-nav a.dropdown-item {
    /* color: #122051 !important; */
    padding: 15px 16px;
    text-align: left;
}

/* .menu .navbar-nav a.dropdown-item:hover {
    border: 1px solid #03A796;
    background: rgba(3, 167, 150, 0.10);
    background: #202E4B;
} */

.menu .navbar-nav a.dropdown-item {
    color: #ffffff !important;
    border-bottom: 1px solid #03172E;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.menu .navbar-nav a.dropdown-item:last-child {
    color: #ffffff !important;
    border-bottom: none;
    
}


.menu .navbar-nav a.dropdown-item :hover ,.menu .navbar-nav a.dropdown-item.active {
    color: #ffffff !important;
}

hr.dropdown-divider {
    border-top: 1px solid #BEBEBE;
    width: 90%;
    margin: auto;
}

.menu .navbar-nav a:hover {
    color: #ffffff;
} 

.dropdown-item:hover {
    /* color: #122051 !important; */
    background: transparent;
}

.menu .navbar-nav .nav-link {
    position: relative;
    color: #FFF;
    font-family: Inter;
    font-size: 16px !important;
    font-style: normal;
    line-height: 150% !important; 
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;

}


.menu .navbar-nav .nav-link.active::before,
/* .menu .nav-item.dropdown:has(a.active):before , */
.menu .navbar-nav .show .dropdown-item .active>.nav-link::before {
    position: absolute;
    content: "";
    left: 0px;
    bottom: 0px;
    height:3px;
    width: 70%;
    /* background: linear-gradient(270deg, rgba(241,21,118,1) 0%, rgba(1,198,178,1) 20%, rgba(241,21,118,1) 40%, rgba(1,198,178,1) 60%, rgba(241,21,118,1) 80%, rgba(1,198,178,1) 100%); */
    background: linear-gradient(270deg, rgba(241,21,118,1) 0%, rgba(1,198,178,1) 20%, rgba(241,21,118,1) 100%);
    background: #1EEDA2;
}

/* offcanvas */

.offcanvas.offcanvas-end {
    width: 100%;
   
}


button.navbar-toggler.collapsed {
    border: none;
    box-shadow:none
}


/* span.navbar-toggler-icon {
    color: #B2E219 !important;
    background-image: url('../images/toggler-icon.png');
}

.offcanvas-header .btn-close {
    background-image: url('../images/close-icon.png');
    margin-right: 30px;
} */

.offcanvas-header .btn-close {
    background-image: url('../images/xmark-solid.svg');
}

.offcanvas a.nav-link {
    /* color: #ffffff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding: 30px 0px;
    border-bottom: 1px solid rgba(190, 190, 190, 0.5); */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0px;
    text-decoration: none;
    border-bottom: none;
    color: #FFFFFF;
}


.dropdown-item.active, .dropdown-item:active {
    background: transparent;
    color: #ffffff;
}


@media only screen  and (min-device-width : 320px)  and (max-device-width : 479px)  
{
    .menu .hero.container-fluid {
        padding: 20px 20px 0px !important;
    }

    .menu .navbar {
        display: flex;
        justify-content: space-between;
        padding: 0px !important;
    }

    .menu .navbar a.navbar-brand img {
        max-width: 120px;
    }

    .offcanvas-header .offcanvas-title img {
      max-width: 150px;
    }

    .offcanvas-header {
        padding-left: 20px;
        padding-right: 30px;
    }

    .offcanvas.offcanvas-end {
        width: 100%;
        background: rgba(241, 21, 118, 1);
        /* background-image: url('../images/headermenubg-mb.webp'); */
        background: linear-gradient(0deg, #01C6B2 0%, #048D7F 98.44%);
        background: linear-gradient(172deg, #041E30 0%, #000 100%);
        background-repeat: no-repeat;
        background-size: cover;
    }    

    .offcanvas-body .justify-content-end.mobilebiew.navbar-nav {
        align-items: unset;
    }

    .offcanvas-body .btn-box {
        margin-top: 100px;
        padding: 26px 0px;
        border-top: 1px solid rgba(190, 190, 190, 0.5);
    }

    .btn-box button{

        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 20px !important;
        line-height: 20px !important;
        text-align: center;
        letter-spacing: 0.02em;
        padding-top: 25px;
        padding-bottom:25px;
        color: #122051;
    }

    .offcanvas a {
        color: #ffffff !important;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        padding: 26px 0px;
        text-decoration: none;
    }

    .offcanvas .borderbottom{
        border-bottom: 1px solid rgba(190, 190, 190, 0.5);
    }
    .bordertop{
        border-top: 1px solid rgba(190, 190, 190, 0.5);
    }



    .offcanvas-body .dropdown-menu.show {
        background: transparent;
        border: none;
        padding-left: 20px;
    }
    
    .dropdown-menu.show hr.dropdown-divider {
        display: none;
    }
    
    .offcanvas-body .dropdown-menu.show a.nav-link {
        padding: 10px 0;
    }

    .menu .navbar-nav .nav-link.active::before {
        content:unset;
    }
    
    .offcanvas-body .justify-content-end.mobilebiew.navbar-nav {
        align-items: center;
    }
    
}

@media only screen  and (min-device-width : 480px)  and (max-device-width : 767px)  
{
    .menu .hero.container-fluid {
        padding: 20px 20px 0px !important;
    }

    .menu .navbar {
        display: flex;
        justify-content: space-between;
        padding: 0px !important;
    }
    
    .menu .navbar a.navbar-brand img {
        max-width: 180px;
    }

    .offcanvas-header .offcanvas-title img {
      max-width: 150px;
    }

    .offcanvas-header {
        padding-left: 20px;
        padding-right: 30px;
    }

    .offcanvas.offcanvas-end {
        width: 100%;
        background: rgba(241, 21, 118, 1);
        /* background-image: url('../images/headermenubg-mb.webp'); */
        background: linear-gradient(0deg, #01C6B2 0%, #048D7F 98.44%);
        background: linear-gradient(172deg, #041E30 0%, #000 100%);
        background-repeat: no-repeat;
        background-size: cover;
        transition: all .5s;
    }    

    .offcanvas-body .justify-content-end.mobilebiew.navbar-nav {
        align-items: unset;
    }

    .offcanvas-body .btn-box {
        margin-top: 100px;
        padding: 26px 0px;
        border-top: 1px solid rgba(190, 190, 190, 0.5);
    }

    .btn-box button{

        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 20px !important;
        line-height: 20px !important;
        text-align: center;
        letter-spacing: 0.02em;
        padding-top: 25px;
        padding-bottom:25px;
        color: #122051;
    }

    .offcanvas a {
        color: #ffffff !important;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        padding: 16px 0px;
        text-decoration: none;
    }

    .offcanvas .borderbottom{
        border-bottom: 1px solid rgba(190, 190, 190, 0.5);
    }
    .bordertop{
        border-top: 1px solid rgba(190, 190, 190, 0.5);
    }



    .offcanvas-body .dropdown-menu.show {
        background: transparent;
        border: none;
        padding-left: 20px;
    }
    
    .dropdown-menu.show hr.dropdown-divider {
        display: none;
    }
    
    .offcanvas-body .dropdown-menu.show a.nav-link {
        padding: 10px 0;
    }
    
    .menu .navbar-nav .nav-link.active::before {
        content:unset;
    }

    .offcanvas-body .justify-content-end.mobilebiew.navbar-nav {
        align-items: center;
    }
    
}

@media only screen  and (min-device-width : 767px) {

    .offcanvas.offcanvas-end.show .offcanvas-header .offcanvas-title img {
      max-width: 250px;
    }

    .offcanvas.offcanvas-end.show .offcanvas-header {
        padding-left: 20px;
        padding-right: 30px;
    }


    .offcanvas.offcanvas-end {
        transition: all 0.2s;
    }

    .offcanvas.offcanvas-end.show {
        width: 100%;
        background: linear-gradient(172deg, #041E30 0%, #000 100%);
        background-repeat: no-repeat;
        background-size: cover;
    }    

    .offcanvas.offcanvas-end.show .offcanvas-body .justify-content-end.mobilebiew.navbar-nav {
        align-items: unset;
    }

    .offcanvas.offcanvas-end.show .offcanvas-body .btn-box {
        margin-top: 100px;
        padding: 26px 0px;
        border-top: 1px solid rgba(190, 190, 190, 0.5);
    }

    .offcanvas.offcanvas-end.show .btn-box button{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 20px !important;
        line-height: 20px !important;
        text-align: center;
        letter-spacing: 0.02em;
        padding-top: 25px;
        padding-bottom:25px;
        color: #122051;
    }

    .offcanvas.offcanvas-end.show a {
        color: #ffffff !important;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        padding: 26px 0px;
        text-decoration: none;
    }

    .offcanvas.offcanvas-end.show .borderbottom{
        border-bottom: 1px solid rgba(190, 190, 190, 0.5);
    }
    .bordertop{
        border-top: 1px solid rgba(190, 190, 190, 0.5);
    }

    .offcanvas.offcanvas-end.show .offcanvas-body .dropdown-menu.show {
        background: transparent;
        border: none;
        padding-left: 20px;
    }
    
    .offcanvas.offcanvas-end.show .dropdown-menu.show hr.dropdown-divider {
        display: none;
    }
    
    .offcanvas.offcanvas-end.show .offcanvas-body .dropdown-menu.show a.nav-link {
        padding: 10px 0;
    }
    
    .offcanvas.offcanvas-end.show .offcanvas-body .justify-content-end.mobilebiew.navbar-nav {
        align-items: center;
    }
 
}

@media (min-width: 768px) and (max-width: 1024px) {
    .menu .hero.container-fluid {
        padding: 1rem 1.5rem 0px !important;
    }

    .navbar-brand img {
        max-width: 120px;
    }

    .navbar-expand-md .navbar-nav .nav-link {
        margin: 0px 1px;   
    }
    
    .navbar-expand-md .navbar-nav .nav-link {
        line-height: 18px;
    }

    .offcanvas a.nav-link {
        font-size: 11px;
        line-height: 12px;
    }

    .menu .navbar-nav a {
        font-size: 12px !important;
        line-height: 150% !important;
    }

    .menu .navbar-nav .nav-link {
        font-size: 12px !important;        
    }

    nav.navbar-dark.ps-3.pe-5.mb-3.navbar.navbar-expand-md.navbar-light.bg-transparent {
        padding-right: 1rem !important;
    }
    
    
}
