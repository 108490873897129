.catapulating-section {
    background: url('../images/bg-catapulation.webp');
    background-position: top center;
    background-size: cover;
    padding-top: 100px !important;
    padding-bottom: 2rem!important    
}

.catapulating-section h2 {
    color: #FFF;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 60px */
}

.catapulating-section h2 span {
    color: #1EC337;
    font-family: Lora;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}

.catapulating-section .bl-green .text-box p {
    color: #FFF;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 33px; 
}
.bl-green:before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    background: #1EEDA2;
    top: 0%;
    left: -30px;
}


.bl-green .text-box::before {
    content: "";
    width: 11px;
    height: 100%;
    position: absolute;
    background: #1EEDA2;
    top: 0%;
    left: -30px;
}


/* anima-box */
.catapulating-section .anima-boxes {
    margin-top: 50px;
    padding-top: 0em;
    padding-bottom: 5em;
    --bs-gutter-x: 3em;
}

.catapulating-section .anima-boxes .anima-box {
    display: flex;
    flex-direction: column;
    position: relative;
    transition: hover 1s;
}

.catapulating-section .anima-boxes .anima-box .img-box img {
    width: 100%;
    border-radius: 20px 20px 0 0;
}

.catapulating-section .anima-boxes .anima-box .text-box,
.catapulating-section .anima-boxes .anima-box .text-box.blue,
.catapulating-section .anima-boxes .anima-box .text-box.green,
.catapulating-section .anima-boxes .anima-box .text-box.yellow {
    position: absolute;
    cursor: pointer;
    width: 100%;
    bottom:-169px;
    height: 170px;
    padding: 25px 30px 15px;
    height: 190px;
    padding: 25px 20px 15px;
    border-radius: 0 0 20px 20px;
    background: linear-gradient(108deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.18) 100%);
    backdrop-filter: blur(10px);
    transition: all 1s;
}

.catapulating-section .anima-boxes .anima-box .text-box h3 {
    color: #FFF;
    font-family: Montserrat;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.catapulating-section .anima-boxes .anima-box .text-box h4 {
    color: #FFF;
    font-family: Montserrat;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    line-height: 150%;
}

.catapulating-section .anima-boxes .anima-box .text-box p {
    /* display: none; */
    color: #FFF;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    transform: translateY(20px);
    height: 0;
    visibility: hidden;
    opacity: 0;
    /* transition: all 2s; */
    transition: visibility 0s, opacity .5s ,display .8s,  transform 1s linear; 

}

.catapulating-section .anima-boxes .anima-box:hover .text-box p {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
    height: unset;
}

.catapulating-section .anima-boxes .anima-box:hover .text-box.blue {
    height: unset;
    background: #0281DF;
}

.catapulating-section .anima-boxes .anima-box:hover .text-box.green {
    height: unset;
    background: #31B6A8;
}

.catapulating-section .anima-boxes .anima-box:hover .text-box.yellow {
    height: unset;
    background: #BAAF3D;
}

.catapulating-section h1 { 
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
}


.catapulating-section .partners-5 {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    justify-content: space-between;
    align-items: center;
}

.catapulating-section .partners-6 {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    justify-content: space-between;
    align-items: center;
}

.catapulating-section .btn-row {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.catapulating-section .partners-6.mobile ,
.catapulating-section .partners-5.mobile,
.catapulating-section .partners-6.tablet {
    display: none !important;
}


@media screen and (max-width: 767px) { 
    .catapulating-section {
        padding-top: 50px !important;
    }

    .catapulating-section h2 {
        font-size: 22px;
    }

    .catapulating-section h2 span {
        font-size: 24px;
    }

    .catapulating-section .bl-green .text-box p {
        font-size: 16px;
        line-height: 24px;
    }

    .catapulating-section .anima-boxes {
        padding-bottom: 1rem;
    }
    .catapulating-section .anima-boxes .anima-box {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }

    .catapulating-section .anima-boxes .anima-box .text-box {
        position: inherit !important;
        bottom: 0px !important;
        height: unset !important;
    }

    .catapulating-section .anima-boxes .anima-box .text-box h3 {
        font-size: 18px;
        line-height: 27px;
    }

    .catapulating-section .anima-boxes .anima-box .text-box h4 {
        font-size: 14px;
        line-height: 21px;
    }
    
    .catapulating-section .anima-boxes .anima-box .text-box p {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
        height: unset;
        font-size: 12px;
    }
    .catapulating-section .btn-row {
        padding: 0px;
    }

    .main-btn .main-btn {
        font-size: 14px;
        line-height: 14px;
    }
    
    .catapulating-section h1 {
        font-size: 24px;
    }

 
    .catapulating-section .partners-6.desktop ,
    .catapulating-section .partners-5.desktop {
        display: none !important;
    }

    .catapulating-section .partners-6.mobile {
        display: grid !important;
        grid-template-columns: auto auto auto;
        justify-content: space-between;
        align-items: left;
        text-align: left;
        gap: 20px 10px;
        padding-left: 15px;
        padding-right: 15px;
        margin: auto;
    }

    .catapulating-section .partners-6.mobile img {
        width: auto;
        text-align: center;
        margin: auto;
        max-height: 30px;
    }

    
    .catapulating-section .partners-5.mobile {
        display: grid !important;
        margin-top: 20px;
        grid-template-columns: auto auto auto;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 40px;
    }

    .catapulating-section .partners-5 img {
        width: auto;
        max-height: 35px;
        margin: auto;
    }
}


@media (min-width: 768px) and (max-width: 1024px) { 
    .catapulating-section {
        padding-left: 3%;
        padding-right: 3%;
        padding-top: 30px !important;
    }

    .catapulating-section h2 {
        font-size: 22px;
    }

    .catapulating-section h2 span {
        font-size: 24px;
    }

    .catapulating-section .bl-green .text-box p {
        font-size: 16px;
        line-height: 24px;
    }

    .catapulating-section .anima-boxes {
        padding-bottom: 1rem;
    }

    .catapulating-section .anima-boxes .col-md-4 {
        width: 66.66663%;
        margin-left: auto;
        margin-right: auto;
    }

    .catapulating-section .anima-boxes .anima-box {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }

    .catapulating-section .anima-boxes .anima-box .text-box {
        position: inherit !important;
        bottom: 0px !important;
        height: unset !important;
    }

    .catapulating-section .anima-boxes .anima-box .text-box h3 {
        font-size: 18px;
        line-height: 27px;
    }

    .catapulating-section .anima-boxes .anima-box .text-box h4 {
        font-size: 14px;
        line-height: 21px;
    }
    
    .catapulating-section .anima-boxes .anima-box .text-box p {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
        height: unset;
        font-size: 12px;
    }
    .catapulating-section .btn-row {
        padding: 0px;
    }

    .main-btn .main-btn {
        font-size: 14px;
        line-height: 14px;
    }
    
    .catapulating-section h1 {
        font-size: 24px;
    }

 
    .catapulating-section .partners-6.desktop ,
    .catapulating-section .partners-5.desktop {
        display: none !important;
    }

    .catapulating-section .partners-6.tablet {
        display: grid !important;
        grid-template-columns: auto auto auto;
        justify-content: space-between;
        align-items: left;
        text-align: left;
        gap: 20px 10px;
        padding-left: 15px;
        padding-right: 15px;
        margin: auto;
    }

    .catapulating-section .partners-6.tablet img {
        width: auto;
        text-align: center;
        margin: auto;
        max-height: unset;
    }
}


@media (orientation: landscape) and (max-width: 1400px) {
 
 
    .catapulating-section .partners-6.desktop ,
    .catapulating-section .partners-5.desktop {
        display: none !important;
    }

    .catapulating-section h1 {
        margin-bottom: 50px;
    }

    .catapulating-section .partners-6.tablet {
        display: grid !important;
        grid-template-columns: auto auto auto auto;
        justify-content: space-between;
        align-items: left;
        text-align: left;
        gap: 40px 10px;
        padding-left: 15px;
        padding-right: 15px;
        margin: auto;
    }


}
