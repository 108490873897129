.section-addresses {
    padding-top:100px;
    padding-bottom:100px;
    position: relative;
    /* background:url('../images/address-bg.webp'); */
}

.section-addresses video#background-video {
    width: 100%;
    position: absolute;
    object-fit: cover;
    left: 0;
    top: 0;
    height: 100%;
    z-index: -1;
}


.section-addresses .heading-box {
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.section-addresses .heading-box h1 {
    color: #ffffff;
    text-align: center;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 60px */
}

.section-addresses .heading-box h1 span {
    color: #1EC337;
    font-family: Lora;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}


.section-addresses .addresses {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 40px;
}

.section-addresses .addresses .address {
    display: flex;
    flex-direction: column;
    gap:10px;
    justify-content: space-between;
    position: relative;
    padding-bottom: 20px;
}

.section-addresses .addresses .address:after {
    content: "";
    background: #1EEDA2;
    height: 6px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 50%;
}


.section-addresses .addresses .address h4 {
    color: #ffffff;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%; /* 60px */
}

.section-addresses .addresses .address h5 {
    color: #ffffff;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%; /* 60px */
}

.section-addresses .addresses .address p,
.section-addresses .addresses .address a {
    color: #ffffff;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%; 
    text-decoration: none;
}


@media screen and (max-width: 768px) {  

.section-addresses {
    padding-top: 0px;
    padding-bottom: 50px;
    position: relative; 
    background: linear-gradient(139.14deg, #000000 46.48%, #0281DF 286.62%);
}

.section-addresses .mobile-video {
    width: 100%;
    position: relative;
    object-fit: cover;
    left: 25%;
    height: 100%;
    z-index: 1;
}

.section-addresses .col-md-6.offset-6 {
    width: 100%;
    margin-left: 0px !important;
}

.section-addresses .addresses .address {
    padding-bottom: 10px;
}

.section-addresses .heading-box h1,
.section-addresses .heading-box h1 span {
    font-size: 22px;
    line-height: 150%;
}

.section-addresses .addresses .address h4 {
    font-size: 14px;
    font-weight: 600;
}


.section-addresses .addresses .address h4 span {
    font-size: 12px;
    font-weight: 400;
}

.section-addresses .addresses .address h5 {
    font-size: 12px;
    line-height: 150%; /* 60px */
}

.section-addresses .addresses .address p,
.section-addresses .addresses .address a {
    font-size: 12px;
}

.section-addresses .addresses .address:after {
    height: 4px;
}

}

@media (min-width: 768px) and (max-width: 1024px) {

    .section-addresses {
        padding-top: 0px;
        padding-bottom: 50px;
        position: relative; 
        background: linear-gradient(139.14deg, #000000 46.48%, #0281DF 286.62%);
    }
    
    .section-addresses .mobile-video {
        width: 100%;
        position: relative;
        object-fit: cover;
        left: 25%;
        height: 100%;
        z-index: 1;
    }
    
    .section-addresses .col-md-6.offset-6 {
        width: 100%;
        margin-left: 0px !important;
    }
    
    .section-addresses .addresses .address {
        padding-bottom: 10px;
    }
    
    .section-addresses .heading-box h1,
    .section-addresses .heading-box h1 span {
        font-size: 22px;
        line-height: 150%;
    }
    
    .section-addresses .addresses .address h4 {
        font-size: 14px;
        font-weight: 600;
    }
    
    
    .section-addresses .addresses .address h4 span {
        font-size: 12px;
        font-weight: 400;
    }
    
    .section-addresses .addresses .address h5 {
        font-size: 12px;
        line-height: 150%; /* 60px */
    }
    
    .section-addresses .addresses .address p,
    .section-addresses .addresses .address a {
        font-size: 12px;
    }
    
    .section-addresses .addresses .address:after {
        height: 4px;
    }
    
    }