.singlebody-section .header {
    position: relative;
    color: #ffffff;
    text-align: center;
    margin-top: 50px;
}

.singlebody-section .header .navigation {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.07px;
    text-align: left;
    color:#0281DF;
    margin-bottom: 20px;
    text-transform: capitalize;
}

.singlebody-section .header .navigation a {
    text-decoration: none;
}

.singlebody-section .header .navigation span:before {
    content: ">";
    color:#000000;
    padding: 0px 5px;
}

.singlebody-section .header .navigation span:last-child {
    color:#121416;
}

.singlebody-section .header img.banner-img {
    width: 100%;
    height: auto;
}

.singlebody-section .header .blog-heading {
    position: absolute;
    width: 80%;
    left: 50%;
    bottom: 50px;
    transform: translateX(-50%);
}

.singlebody-section .header .blog-heading .d-flex {
    justify-content: flex-start;
    gap: 25px;
    align-items: center;
}

.singlebody-section .header .blog-heading h2.main-heading {
    font-family: Montserrat;
    font-size: 31.31px;
    font-weight: 400;
    line-height: 38.17px;
    letter-spacing: 0.39135801792144775px;
    text-align: left;
}

.singlebody-section .header .author-des,
.singlebody-section .header .readtime {
    display: flex;
    align-items: center;
    gap: 10px;
}

.singlebody-section .header .readtime svg {
    height: 20px;
    width: 20px;
}

.singlebody-section .header .author-des span,
.singlebody-section .header .readtime span {
    font-family: Montserrat;
    font-size: 15.65px;
    font-weight: 400;
    line-height: 31.31px;
    text-align: left;
    text-transform: capitalize;
}

.singlebody-section .header .author-des span img {
    border-radius: 50px;
}


.singlebody-section .blog-text .blog-content {
    font-family: Montserrat;
    color: #121416;

}

.singlebody-section .blog-text .blog-content h2 {
    font-size: 40px;
    font-weight: 400;
    line-height: 48.76px;
    text-align: left;    
}

.singlebody-section .blog-text .blog-content h3 {
    font-family: Montserrat;
    font-size: 26px;
    font-weight: 500;
    line-height: 33px;
    text-align: left;
    color: #000000;    
}


.singlebody-section .blog-text .blog-content h4 {
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #121416;  
}

.singlebody-section .blog-text .blog-content p,
.singlebody-section .blog-text .blog-content ul li {
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
}

/* related post */

.singlebody-section .related-post .insights .insight .relative {
    position: relative;
}

.singlebody-section .related-post .insights .insight a {
    text-decoration: none;
}

.singlebody-section .related-post h3.rp-heading {
    font-family: Montserrat;
    font-size: 40px;
    font-weight: 400;
    line-height: 48.76px;
    text-align: left;
    color:#121416;
    margin-bottom: 1em;
}

.singlebody-section .related-post .insights .insight img {
    width: 100%;
}

.singlebody-section .related-post .insights .insight .over {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 0px;
    padding: 10px;
    width: 100%;
    color: #ffffff;
}

.singlebody-section .related-post .insights .insight .over .readtime {
    font-family: Montserrat;
    font-size: 9.45px;
    font-weight: 400;
    line-height: 17.38px;
    text-align: left;
    display: flex;
    gap: 5px;
    background: #ffffff40;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
}

.singlebody-section .related-post .insights .insight .over .share {
    padding: 5px 15px;
    border-radius: 50px;
    background: #ffffff20;
}

.singlebody-section .related-post .insights .insight .author-line {
    display: flex;
    justify-content: space-between;
}

.singlebody-section .related-post .insights .insight .author-line .author  {
    display: flex;
    gap:5px;
    align-items: center;
}

.singlebody-section .related-post .insights .insight .author-line .author .author-icon {
    max-width: 25px;
    max-height: 25px;
}

.singlebody-section .related-post .insights .insight .author-line .author .author-icon img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
}

.singlebody-section .related-post .insights .insight .author-line .author .author-des div:first-child {
    font-family: Montserrat;
    font-size: 14.18px;
    font-weight: 400;
    line-height: 21.48px;
    text-align: left;    
}

.singlebody-section .related-post .insights .insight .author-line .author .author-des div:last-child {
    font-family: Montserrat;
    font-size: 11.03px;
    font-weight: 400;
    line-height: 17.64px;
    text-align: left;
    color: #909090;
}

.singlebody-section .related-post .insights .insight .author-line .share {
    font-family: Montserrat;
    font-size: 9.67px;
    font-weight: 400;
    line-height: 21.48px;
    text-align: left;
    color: #6C757D;
}

.singlebody-section .related-post .insights .insight h2 {
    color: #121416;
    font-family: Montserrat;
    font-size: 18.9px;
    font-weight: 600;
    line-height: 26.47px;
    text-align: left;
}

.singlebody-section .related-post .insights .insight p {
    color: #6C757D;
    font-family: Montserrat;
    font-size: 10.74px;
    font-weight: 400;
    line-height: 16.08px;
    text-align: left;    
}


.singlebody-section .related-post .insights .insight .link {
    font-family: Montserrat;
    font-size: 12.89px;
    font-weight: 400;
    line-height: 21.48px;
    text-align: left;
    color: #121416;    
    text-decoration: none;
    position: relative;
}

.singlebody-section .related-post .insights .insight .link:after {
    content: "";
    position: absolute;
    height: 1px;
    width: 94%;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    background: #121416;
}



.swiper-button-next::after {
    content: url('../images/btn-next.png');
}

.swiper-button-prev::after {
    content: url('../images/btn-prev.png');
}

.singlebody-section .related-post .insights.swiper {
    padding-top: 100px;
    transform: translateY(-100px);
    padding-bottom: 50px;
}

.singlebody-section .related-post .insights .swiper-button-prev {
    padding: 8px 16px;
    border-radius: 100%;
    top: 50px;
    right: 100px;
    left: auto;
}

.singlebody-section .related-post .insights .swiper-button-next {
    padding: 8px 16px;
    border-radius: 100%;
    top: 50px;
    right: 20px;
    left: auto;
  }


  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) { 
    .singlebody-section .header {
        margin-top: 30px;
    }
    
    .singlebody-section .header .blog-heading h2.main-heading {
        font-size: 20px;
        line-height: 26px;
    }

    .singlebody-section .header .author-des span,
    .singlebody-section .header .readtime span  {
        font-size: 10px;
        line-height: 15px;
    }

    .singlebody-section .blog-text .blog-content h2 {
        font-size: 26px;
        line-height: 33px;
    }

    .singlebody-section .blog-text .blog-content h3 {
        font-size: 22px;
        line-height: 28px;
    }

    .singlebody-section .blog-text .blog-content h4 {
        font-size: 18px;
        line-height: 24px;
    }

    .singlebody-section .blog-text .blog-content h4 img {
        width: 29px;
        height: 29px;   
    }

    .singlebody-section .blog-text .blog-content p, .singlebody-section .blog-text .blog-content ul li {
        font-size: 12px;
        line-height: 20px;
    }

    .divider {
        margin:25px 0px;
    }

    .singlebody-section .related-post h3.rp-heading {
        font-size: 26px;
        line-height: 33px;     
    }

    .singlebody-section .blog-text .blog-content p img {
        width: 18px;
        height: 18px;
    }

    .singlebody-section .related-post .insights .insight h2 {
        font-size: 16px;
        line-height: 20px;     
    }
  }