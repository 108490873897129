.wwerabout-section {
    padding-top: 50px;
    padding-bottom: 50px;
}

.wwerabout-section .text-box h1 {
    color: #000;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
}

.wwerabout-section .text-box h1 span {
    color: #1EC337;
    font-family: Lora;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}

.wwerabout-section p {
    color: #1A202C;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.wwerabout-section p span {
    font-family: Lora;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #1EC337;
}

@media screen and (max-width: 767px) {  

    .wwerabout-section .text-box h1 {        
        font-size: 22px;
        line-height: 33px;
    }
    
    .wwerabout-section .text-box h1 span {
        font-size: 22px;
        line-height: 33px;
    }
    

    .wwerabout-section .text-box p {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;    
    }

    .wwerabout-section p,
    .wwerabout-section p span {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;    
    }

    .wwerabout-section img {
        width: 40%;
    }
}


@media (min-width: 768px) and (max-width: 1024px) {

    .wwerabout-section .text-box h1 {        
        font-size: 22px;
        line-height: 33px;
    }
    
    .wwerabout-section .text-box h1 span {
        font-size: 22px;
        line-height: 33px;
    }
    

    .wwerabout-section .text-box p {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;    
    }
 
    .wwerabout-section img {
        width: 100%;
    }

}